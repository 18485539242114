body{ min-height:100vh; margin:0; position:relative; }
header{ min-height:50px; background:lightcyan; }

body {
  position: relative;
}

body::after {
  content: '';
  display: block;
  height: 80px;
}

footer {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
}
.btn.btn-custom {
  color: #ffffff;
  background: #dc6b2f;
  border-color: transparent;
}
.btn.btn-custom:hover {
  color: #ffffff!important;
  background: #002855!important;
  border-color: transparent!important;
}
.img-onerror {
  text-align: center;
}
.img-onerror:before {
  content: "File is not exists";
}
.img-onerror .file-source {
  display: none;
}
.file-source {
  border:2px solid #fff;
  box-shadow: 10px 10px 5px #ccc;
  -moz-box-shadow: 10px 10px 5px #ccc;
  -webkit-box-shadow: 10px 10px 5px #ccc;
  -khtml-box-shadow: 10px 10px 5px #ccc;
}
